import React from "react";
// import { Link } from "gatsby"

import Layout from "../components/layout";

import SEO from "../components/seo";

const Page = () => (
  <Layout>
    <SEO
      title="Products We Carry"
      keywords={[
        `New Hope Pet Center Products`,
        `New Hope`,
        `Pet Center`,
        `Pet Grooming`,
        `Pet Supplies`
      ]}
    />
    <div>
      <div className="mw8 center pb5">
        <div className="ph3 center tc lh-title pv4 mw7">
          <h1 className="f3 f2-ns lh-title fw9 mb3 mt0 pv3 bb-ns b--light-gray bw2">
            Products We Carry
          </h1>
        </div>
        <div className="pa3 pa4-ns measure center mb4">
          <h2 className="f5 f4-l b lh-copy">For your Dog and Cat:</h2>
          <ul className="list pa0 ">
            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
              CBD Products - Oils, treats, sprays.
            </li>
            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
              Health Care Supplements - Skin, Joint, and Digestive aids.
            </li>
            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
              Healthy Treats - Soft, hard, Freeze Dried, Dehydrated, Natural
              Bones, Jerky{" "}
            </li>
            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
              Grooming supplies and tools
            </li>
            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
              Sanitation and cleaning supplies
            </li>
          </ul>
        </div>

        <div className="pa3 pa4-ns measure center mb4">
          <h2 className="f5 f4-l b lh-copy">Reptile:</h2>
          <ul className="list pa0 ">
            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
              Live crickets and mice, bedding, supplements
            </li>
          </ul>
        </div>

        <div className="pa3 pa4-ns measure center mb4">
          <h2 className="f5 f4-l b lh-copy">Bird & Small Animal:</h2>
          <ul className="list pa0 ">
            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
              Food, bedding
            </li>
          </ul>
        </div>

        <div className="pa3 pa4-ns measure center mb4">
          <h2 className="f5 f4-l b lh-copy">Fish:</h2>
          <ul className="list pa0 ">
            <li className="lh-copy pv3 ba bl-0 bt-0 br-0 b--dotted b--black-30">
              Water treatments, food, pond food.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
);

export default Page;
